import {
  UPDATE_APP_TEXT_ASTROLOGER_INIT,
  UPDATE_APP_TEXT_ASTROLOGER_SUCCESS,
  UPDATE_APP_TEXT_ASTROLOGER_ERROR,
  GET_APP_TEXT_ASTROLOGER_INIT,
  GET_APP_TEXT_ASTROLOGER_SUCCESS,
  GET_APP_TEXT_ASTROLOGER_ERROR,
  UPDATE_APP_TEXT_INIT,
  UPDATE_APP_TEXT_SUCCESS,
  UPDATE_APP_TEXT_ERROR,
  GET_LANGUAGE_INIT,
  GET_LANGUAGE_SUCCESS,
  GET_LANGUAGE_ERROR,
  UPDATE_TERMS_AND_CONDITION_INIT,
  UPDATE_TERMS_AND_CONDITION_SUCCESS,
  UPDATE_TERMS_AND_CONDITION_ERROR,
  GET_TERMS_AND_CONDITIONS_INIT,
  GET_TERMS_AND_CONDITIONS_SUCCESS,
  GET_TERMS_AND_CONDITIONS_ERROR,
  DELETE_FAQ_INIT,
  DELETE_FAQ_SUCCESS,
  DELETE_FAQ_ERROR,
  GET_FAQ_INIT,
  GET_FAQ_SUCCESS,
  GET_FAQ_ERROR,
  ADD_FAQ_INIT,
  ADD_FAQ_SUCCESS,
  ADD_FAQ_ERROR,
  DELETE_IMAGE_CAROUSEL_INIT,
  DELETE_IMAGE_CAROUSEL_SUCCESS,
  DELETE_IMAGE_CAROUSEL_ERROR,
  GET_IMAGE_CAROUSEL_INIT,
  GET_IMAGE_CAROUSEL_SUCCESS,
  GET_IMAGE_CAROUSEL_ERROR,
  SAVE_IMAGE_CAROUSEL_INIT,
  SAVE_IMAGE_CAROUSEL_SUCCESS,
  SAVE_IMAGE_CAROUSEL_ERROR,
  GET_WITHDRAWAL_SETTINGS_INIT,
  GET_WITHDRAWAL_SETTINGS_SUCCESS,
  GET_WITHDRAWAL_SETTINGS_ERROR,
  SET_WITHDRAWAL_PERCENTAGE_INIT,
  SET_WITHDRAWAL_PERCENTAGE_SUCCESS,
  SET_WITHDRAWAL_PERCENTAGE_ERROR,
  UPDATE_PRIVACY_POLICY_INIT,
  UPDATE_PRIVACY_POLICY_SUCCESS,
  UPDATE_PRIVACY_POLICY_ERROR,
  GET_PRIVACY_POLICYS_INIT,
  GET_PRIVACY_POLICYS_SUCCESS,
  GET_PRIVACY_POLICYS_ERROR,
  GET_RAZORPAY_SETTINGS_INIT,
  GET_RAZORPAY_SETTINGS_SUCCESS,
  GET_RAZORPAY_SETTINGS_ERROR,
  SET_RAZORPAY_SETTINGS_INIT,
  SET_RAZORPAY_SETTINGS_SUCCESS,
  SET_RAZORPAY_SETTINGS_ERROR,

  GET_INSTAMOJO_SETTINGS_INIT,
  GET_INSTAMOJO_SETTINGS_SUCCESS,
  GET_INSTAMOJO_SETTINGS_ERROR,
  SET_INSTAMOJO_SETTINGS_INIT,
  SET_INSTAMOJO_SETTINGS_SUCCESS,
  SET_INSTAMOJO_SETTINGS_ERROR,
  
} from "../constants/SettingsConstants.js";

export const setWithdrawalPercentageInit = (data) => ({
  type: SET_WITHDRAWAL_PERCENTAGE_INIT,
  data,
});

export const setWithdrawalPercentageSuccess = (data) => ({
  type: SET_WITHDRAWAL_PERCENTAGE_SUCCESS,
  data,
});

export const setWithdrawalPercentageError = (error) => ({
  type: SET_WITHDRAWAL_PERCENTAGE_ERROR,
  error,
});
export const getWithdrawalSettingsInit = () => ({
  type: GET_WITHDRAWAL_SETTINGS_INIT,
});

export const getWithdrawalSettingsSuccess = (data) => ({
  type: GET_WITHDRAWAL_SETTINGS_SUCCESS,
  data,
});

export const getWithdrawalSettingsError = (error) => ({
  type: GET_WITHDRAWAL_SETTINGS_ERROR,
  error,
});

export const saveImageCarouselInit = (data) => ({
  type: SAVE_IMAGE_CAROUSEL_INIT,
  data,
});

export const saveImageCarouselSuccess = () => ({
  type: SAVE_IMAGE_CAROUSEL_SUCCESS,
});

export const saveImageCarouselError = (error) => ({
  type: SAVE_IMAGE_CAROUSEL_ERROR,
  error,
});
export const getImageCarouselInit = () => ({
  type: GET_IMAGE_CAROUSEL_INIT,
});

export const getImageCarouselSuccess = (data) => ({
  type: GET_IMAGE_CAROUSEL_SUCCESS,
  data,
});

export const getImageCarouselError = (error) => ({
  type: GET_IMAGE_CAROUSEL_ERROR,
  error,
});

export const deleteImageCarouselInit = (data) => ({
  type: DELETE_IMAGE_CAROUSEL_INIT,
  data,
});

export const deleteImageCarouselSuccess = (data) => ({
  type: DELETE_IMAGE_CAROUSEL_SUCCESS,
  data,
});

export const deleteImageCarouselError = (error) => ({
  type: DELETE_IMAGE_CAROUSEL_ERROR,
  error,
});

export const addFaqInit = (data) => ({
  type: ADD_FAQ_INIT,
  data,
});

export const addFaqSuccess = (data) => ({
  type: ADD_FAQ_SUCCESS,
  data,
});

export const addFaqError = (error) => ({
  type: ADD_FAQ_ERROR,
  error,
});

export const getFaqInit = () => ({
  type: GET_FAQ_INIT,
});

export const getFaqSuccess = (data) => ({
  type: GET_FAQ_SUCCESS,
  data,
});

export const getFaqError = (error) => ({
  type: GET_FAQ_ERROR,
  error,
});
export const deleteFaqInit = (data) => ({
  type: DELETE_FAQ_INIT,
  data,
});

export const deleteFaqSuccess = (data) => ({
  type: DELETE_FAQ_SUCCESS,
  data,
});

export const deleteFaqError = (error) => ({
  type: DELETE_FAQ_ERROR,
  error,
});

export const getTermsAndConditionsInit = () => ({
  type: GET_TERMS_AND_CONDITIONS_INIT,
});

export const getTermsAndConditionsSuccess = (data) => ({
  type: GET_TERMS_AND_CONDITIONS_SUCCESS,
  data,
});

export const getTermsAndConditionsError = (error) => ({
  type: GET_TERMS_AND_CONDITIONS_ERROR,
  error,
});
export const updateTermsAndConditionInit = (data) => ({
  type: UPDATE_TERMS_AND_CONDITION_INIT,
  data,
});

export const updateTermsAndConditionSuccess = (data) => ({
  type: UPDATE_TERMS_AND_CONDITION_SUCCESS,
  data,
});

export const updateTermsAndConditionError = (error) => ({
  type: UPDATE_TERMS_AND_CONDITION_ERROR,
  error,
});

export const getLanguageInit = () => ({
  type: GET_LANGUAGE_INIT,
});

export const getLanguageSuccess = (data) => ({
  type: GET_LANGUAGE_SUCCESS,
  data,
});

export const getLanguageError = (error) => ({
  type: GET_LANGUAGE_ERROR,
  error,
});
export const updateAppTextInit = (data) => ({
  type: UPDATE_APP_TEXT_INIT,
  data,
});

export const updateAppTextSuccess = (data) => ({
  type: UPDATE_APP_TEXT_SUCCESS,
  data,
});

export const updateAppTextError = (error) => ({
  type: UPDATE_APP_TEXT_ERROR,
  error,
});

export const getAppTextAstrologerInit = (data) => ({
  type: GET_APP_TEXT_ASTROLOGER_INIT,
  data,
});

export const getAppTextAstrologerSuccess = (data) => ({
  type: GET_APP_TEXT_ASTROLOGER_SUCCESS,
  data,
});

export const getAppTextAstrologerError = (error) => ({
  type: GET_APP_TEXT_ASTROLOGER_ERROR,
  error,
});
export const updateAppTextAstrologerInit = (data) => ({
  type: UPDATE_APP_TEXT_ASTROLOGER_INIT,
  data,
});

export const updateAppTextAstrologerSuccess = (data) => ({
  type: UPDATE_APP_TEXT_ASTROLOGER_SUCCESS,
  data,
});

export const updateAppTextAstrologerError = (error) => ({
  type: UPDATE_APP_TEXT_ASTROLOGER_ERROR,
  error,
});

export const getPrivacyPolicysInit = () => ({
  type: GET_PRIVACY_POLICYS_INIT,
});

export const getPrivacyPolicysSuccess = (data) => ({
  type: GET_PRIVACY_POLICYS_SUCCESS,
  data,
});

export const getPrivacyPolicysError = (error) => ({
  type: GET_PRIVACY_POLICYS_ERROR,
  error,
});
export const updatePrivacyPolicyInit = (data) => ({
  type: UPDATE_PRIVACY_POLICY_INIT,
  data,
});

export const updatePrivacyPolicySuccess = (data) => ({
  type: UPDATE_PRIVACY_POLICY_SUCCESS,
  data,
});

export const updatePrivacyPolicyError = (error) => ({
  type: UPDATE_PRIVACY_POLICY_ERROR,
  error,
});




export const getInstamojoSettingsInit = () => ({
  type: GET_INSTAMOJO_SETTINGS_INIT,
});

export const getInstamojoSettingsSuccess = (data) => ({
  type: GET_INSTAMOJO_SETTINGS_SUCCESS,
  data,
});

export const getInstamojoSettingsError = (error) => ({
  type: GET_INSTAMOJO_SETTINGS_ERROR,
  error,
});

export const setInstamojoSettingsInit = (data) => ({
  type: SET_INSTAMOJO_SETTINGS_INIT,
  data,
});

export const setInstamojoSettingsSuccess = (data) => ({
  type: SET_INSTAMOJO_SETTINGS_SUCCESS,
  data,
});

export const setInstamojoSettingsError = (error) => ({
  type: SET_INSTAMOJO_SETTINGS_ERROR,
  error,
});