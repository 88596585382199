import {
  DashboardOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  DollarCircleOutlined,
  SwapOutlined,
  SettingOutlined,
  MessageOutlined,
  PlusCircleOutlined,
  TransactionOutlined,
  WarningOutlined,
  CloseCircleOutlined
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { FaBeer } from "react-icons/fa";
const dashBoardNavTree = [
  {
    key: "home",
    path: `${APP_PREFIX_PATH}/home`,
    title: "home",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
];
const usersNavTree = [
  {
    key: "users",
    path: `${APP_PREFIX_PATH}/users`,
    title: "Users",
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [],
  },
];
const DoctorsNavTree = [
  {
    key: "doctors",
    path: `${APP_PREFIX_PATH}/astrologers`,
    title: "Astrologers",
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [],
  },
];
const InactiveDoctorsNavTree = [
  {
    key: "inactive-doctors",
    path: `${APP_PREFIX_PATH}/inactive-astrologers`,
    title: "Inactive Astrologers",
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [],
  },
];
const DoctorCategoryNavTree = [
  {
    key: "doctor-category",
    path: `${APP_PREFIX_PATH}/astrologer-category`,
    title: "Category",
    icon: AppstoreAddOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const WithdrawNavTree = [
  {
    key: "withdraw",
    path: `${APP_PREFIX_PATH}/withdra-request`,
    title: "Withdraw Request",
    icon: DollarCircleOutlined,
    breadcrumb: false,
    submenu: [],
  },
];
const TransactionNavTree = [
  {
    key: "transaction",
    path: `${APP_PREFIX_PATH}/transaction`,
    title: "Transaction",
    icon: TransactionOutlined,
    breadcrumb: false,
    submenu: [],
  },
];
const SettingsNavTree = [
  {
    key: "settings",
    path: `${APP_PREFIX_PATH}/settings`,
    title: "Settings",
    icon: SettingOutlined,
    breadcrumb: false,
    submenu: [],
  },
];
const MessageNavTree = [
  {
    key: "message",
    path: `${APP_PREFIX_PATH}/message`,
    title: "Message",
    icon: MessageOutlined,
    breadcrumb: false,
    submenu: [],
  },
];
const InvoiceNavTree = [
  {
    key: "invoice",
    path: `${APP_PREFIX_PATH}/invoice`,
    title: "Invoice",
    icon: PlusCircleOutlined,
    breadcrumb: false,
    submenu: [],
  },
];
const ProblemNavTree = [
  {
    key: "problem",
    path: `${APP_PREFIX_PATH}/problem`,
    title: "Problem",
    icon: WarningOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const CancelledInvoiceNavTree = [
  {
    key: "cancelled-invoice",
    path: `${APP_PREFIX_PATH}/cancelled-invoice`,
    title: "Cancelled Invoice",
    icon: CloseCircleOutlined,
    breadcrumb: false,
    submenu: [],
  },
];
//NavigationConst

const navigationConfig = [
  //...dashBoardNavTree,
  ...usersNavTree,
  ...DoctorsNavTree,
  ...InactiveDoctorsNavTree,
  ...DoctorCategoryNavTree,

  ...WithdrawNavTree,
  ...TransactionNavTree,
  ...SettingsNavTree,
  ...MessageNavTree,
  ...InvoiceNavTree,
  ...ProblemNavTree,
  ...CancelledInvoiceNavTree
  
  //NavigationConfig
];

export default navigationConfig;
