import {
    SET_INACTIVE_DOCTOR_ACCOUNT_STATUS_INIT,
    SET_INACTIVE_DOCTOR_ACCOUNT_STATUS_SUCCESS,
    SET_INACTIVE_DOCTOR_ACCOUNT_STATUS_ERROR,
    INACTIVE_DOCTOR_FETCH_INIT,
    INACTIVE_DOCTOR_FETCH_SUCCESS,
    INACTIVE_DOCTOR_FETCH_FAIL,
  } from "redux/constants/InactiveDoctor";
  
  const initialState = {
    data: [],
    loading: false,
    error: null,
    success: false,
    delete: false,
  };
  
  const inactivedoctors = (state = initialState, action) => {
    switch (action.type) {
      case INACTIVE_DOCTOR_FETCH_INIT:
        return { ...state, loading: true };
      case INACTIVE_DOCTOR_FETCH_SUCCESS:
        return { ...state, success: true, data: action.data, loading: false };
      case INACTIVE_DOCTOR_FETCH_FAIL:
        return { ...state, error: action.error, loading: false };
      case SET_INACTIVE_DOCTOR_ACCOUNT_STATUS_INIT:
        return { ...state, loading: true, success: false, error: null };
      case SET_INACTIVE_DOCTOR_ACCOUNT_STATUS_SUCCESS:
        const updatedData = state.data.map((doctor) => {
          if (doctor.id === action.doctorId) {
            return { ...doctor, accountStatus: action.status };
          }
          return doctor;
        });
        return { ...state, success: true, data: updatedData, loading: false };
      case SET_INACTIVE_DOCTOR_ACCOUNT_STATUS_ERROR:
        return { ...state, error: action.error, loading: false };
      // ... other cases
      default:
        return state;
    }
  };
  
  export default inactivedoctors;  