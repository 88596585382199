import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  setWithdrawalPercentageError,
  setWithdrawalPercentageSuccess,
  getWithdrawalSettingsSuccess,
  getWithdrawalSettingsError,
  saveImageCarouselSuccess,
  saveImageCarouselError,
  getImageCarouselSuccess,
  getImageCarouselError,
  deleteImageCarouselSuccess,
  deleteImageCarouselError,
  addFaqSuccess,
  addFaqError,
  getFaqSuccess,
  getFaqError,
  deleteFaqSuccess,
  deleteFaqError,
  getTermsAndConditionsSuccess,
  getTermsAndConditionsError,
  updateTermsAndConditionSuccess,
  updateTermsAndConditionError,

  getPrivacyPolicysSuccess,
  getPrivacyPolicysError,
  updatePrivacyPolicySuccess,
  updatePrivacyPolicyError,

  getLanguageSuccess,
  getLanguageError,
  updateAppTextSuccess,
  updateAppTextError,
  getAppTextAstrologerSuccess,
  getAppTextAstrologerError,
  updateAppTextAstrologerSuccess,
  updateAppTextAstrologerError,


  getInstamojoSettingsSuccess,
  getInstamojoSettingsError,
  setInstamojoSettingsSuccess,
  setInstamojoSettingsError,
  //plopImportAction
} from "../actions/SettingsActions.js";
import {
  SET_WITHDRAWAL_PERCENTAGE_INIT,
  GET_WITHDRAWAL_SETTINGS_INIT,
  SAVE_IMAGE_CAROUSEL_INIT,
  GET_IMAGE_CAROUSEL_INIT,
  DELETE_IMAGE_CAROUSEL_INIT,
  ADD_FAQ_INIT,
  GET_FAQ_INIT,
  DELETE_FAQ_INIT,
  GET_TERMS_AND_CONDITIONS_INIT,
  UPDATE_TERMS_AND_CONDITION_INIT,
  GET_PRIVACY_POLICYS_INIT,
  UPDATE_PRIVACY_POLICY_INIT,
  GET_LANGUAGE_INIT,
  UPDATE_APP_TEXT_INIT,
  GET_APP_TEXT_ASTROLOGER_INIT,
  UPDATE_APP_TEXT_ASTROLOGER_INIT,
  SET_INSTAMOJO_SETTINGS_INIT,
  GET_INSTAMOJO_SETTINGS_INIT,
  //plopImportConstant
} from "redux/constants/SettingsConstants.js";

import FirebaseService from "services/FirebaseService";

export function* SetWithdrawalPercentage() {
  yield takeEvery(SET_WITHDRAWAL_PERCENTAGE_INIT, function* (action) {
    try {
      yield call(FirebaseService.setPercentage, action);
      yield put(setWithdrawalPercentageSuccess(action.data));
    } catch (error) {
      yield put(setWithdrawalPercentageError(error));
    }
  });
}
export function* getWithdrawalSettings() {
  yield takeEvery(GET_WITHDRAWAL_SETTINGS_INIT, function* () {
    try {
      const data = yield call(FirebaseService.getWithdrawalSettings);
      yield put(getWithdrawalSettingsSuccess(data));
    } catch (error) {
      yield put(getWithdrawalSettingsError(error));
    }
  });
}
export function* saveImageCarousel() {
  yield takeEvery(SAVE_IMAGE_CAROUSEL_INIT, function* (action) {
    try {
      const urlImage = yield call(
        FirebaseService.uploadImage,
        action.data.fileName,
        action.data.file
      );
      console.log("upload data " + urlImage);
      let data = { imageUrl: urlImage, fileName: action.data.fileName };
      yield call(FirebaseService.saveImageCarouselData, data);
      yield put(saveImageCarouselSuccess());
    } catch (error) {
      yield put(saveImageCarouselError(error));
    }
  });
}
export function* getImageCarousel() {
  yield takeEvery(GET_IMAGE_CAROUSEL_INIT, function* () {
    try {
      const data = yield call(FirebaseService.getImageCarousel);
      console.log(
        "🚀 ~ file: SettingsSaga.js ~ line 64 ~ yieldtakeEvery ~ data",
        data
      );

      let changeTime = data.map((element) => {
        var d = new Date(0);
        d.setUTCSeconds(element.createdAt.seconds);
        return {
          ...element,
          createdAt: `${d}`,
        };
      });

      yield put(getImageCarouselSuccess(changeTime));
    } catch (error) {
      yield put();
    }
  });
}
export function* deleteImageCarousel() {
  yield takeEvery(DELETE_IMAGE_CAROUSEL_INIT, function* (action) {
    console.log(
      "🚀 ~ file: SettingsSaga.js ~ line 89 ~ yieldtakeEvery ~ action",
      action.data
    );
    try {
      yield call(FirebaseService.removeImageCarousel, action);
      yield put(deleteImageCarouselSuccess(action.data));
    } catch (error) {
      yield put(deleteImageCarouselError(error));
    }
  });
}
export function* addFaq() {
  yield takeEvery(ADD_FAQ_INIT, function* (action) {
    try {
      console.log(
        "🚀 ~ file: SettingsSaga.js ~ line 89 ~ yieldtakeEvery ~ action",
        action.data
      );
      let faqId = yield call(
        FirebaseService.addFaq,
        action.data.question,
        action.data.answer
      );
      action.data.id = faqId;
      yield put(addFaqSuccess(action.data));
    } catch (error) {
      yield put(addFaqError(error));
    }
  });
}
export function* getFaq() {
  yield takeEvery(GET_FAQ_INIT, function* () {
    try {
      const data = yield call(FirebaseService.fetchCollection, "Faq");
      yield put(getFaqSuccess(data));
    } catch (error) {
      yield put(getFaqError);
    }
  });
}
export function* deleteFaq() {
  yield takeEvery(DELETE_FAQ_INIT, function* (action) {
    try {
      yield call(FirebaseService.deleteFaq, action.data.id);
      yield put(deleteFaqSuccess(action.data));
    } catch (error) {
      yield put(deleteFaqError(error));
    }
  });
}
export function* getTermsAndConditions() {
  yield takeEvery(GET_TERMS_AND_CONDITIONS_INIT, function* () {
    try {
      const data = yield call(
        FirebaseService.fetchCollection,
        "TermsAndCondition"
      );
     // console.log(data);
      yield put(getTermsAndConditionsSuccess(data));
    } catch (error) {
      yield put(getTermsAndConditionsError(error));
    }
  });
}
export function* updateTermsAndCondition() {
  yield takeEvery(UPDATE_TERMS_AND_CONDITION_INIT, function* (action) {
    try {
      console.log("masuk sini");
      yield call(FirebaseService.updateTermsAndConditions, action.data);

      yield put(updateTermsAndConditionSuccess());
    } catch (error) {
      yield put(updateTermsAndConditionError(error));
    }
  });
}

export function* getPrivacyPolicys() {
  yield takeEvery(GET_PRIVACY_POLICYS_INIT, function* () {
    try {
      console.log("yessssssssssssssssssssssssssss");
      const data = yield call(
        FirebaseService.fetchCollection,
        "PrivacyPolicy"
      );
      
      yield put(getPrivacyPolicysSuccess(data));
      //console.log(data);
    } catch (error) {
      yield put(getPrivacyPolicysError(error));
      //console.log(error);
    }
  });
}
export function* updatePrivacyPolicy() {
  yield takeEvery(UPDATE_PRIVACY_POLICY_INIT, function* (action) {
    try {
      console.log("masuk sini");
      yield call(FirebaseService.updateprivacyPolicys, action.data);

      yield put(updatePrivacyPolicySuccess());
      console.log("Update policy");
    } catch (error) {
      yield put(updatePrivacyPolicyError(error));
      console.log("Update policy");
    }
  });
}
export function* getLanguage() {
  yield takeEvery(GET_LANGUAGE_INIT, function* () {
    try {
      const data = yield call(FirebaseService.getLanguage);
      yield put(getLanguageSuccess(data));
    } catch (error) {
      yield put(getLanguageError(error));
    }
  });
}
export function* updateAppText() {
  yield takeEvery(UPDATE_APP_TEXT_INIT, function* (action) {
    try {
      const data = yield call(FirebaseService.updateAppText, action.data);
      yield put(updateAppTextSuccess(data));
    } catch (error) {
      yield put(updateAppTextError(error));
    }
  });
}
export function* getAppTextAstrologer() {
  yield takeEvery(GET_APP_TEXT_ASTROLOGER_INIT, function* () {
    try {
      const data = yield call(FirebaseService.getAppTextAstrologer);
      yield put(getAppTextAstrologerSuccess(data));
    } catch (error) {
      yield put(getAppTextAstrologerError(error));
    }
  });
}
export function* updateAppTextAstrologer() {
  yield takeEvery(UPDATE_APP_TEXT_ASTROLOGER_INIT, function* (action) {
    console.log(
      "🚀 ~ file: SettingsSaga.js ~ line 224 ~ yieldtakeEvery ~ action",
      action
    );
    try {
      const data = yield call(
        FirebaseService.updateAppTextAstrologers,
        action.data
      );
      yield put(updateAppTextAstrologerSuccess(data));
    } catch (error) {
      yield put(updateAppTextAstrologerError(error));
    }
  });
}



export function* getInstamojoSettings() {
  yield takeEvery(GET_INSTAMOJO_SETTINGS_INIT, function* () {
    try {
      const data = yield call(FirebaseService.getInstamojoSettings);
      console.log(
        "🚀 ~ file: Instamojo data.js ~ line 64 ~ yieldtakeEvery ~ data",
        data
      );
      yield put(getInstamojoSettingsSuccess(data));
    } catch (error) {
      yield put(getInstamojoSettingsError(error));
    }
  });
}

export function* SetInstamojoSettings() {
  yield takeEvery(SET_INSTAMOJO_SETTINGS_INIT, function* (action) {
    try {
      yield call(FirebaseService.setInstamojo, action);
      yield put(setInstamojoSettingsSuccess(action.data));
    } catch (error) {
      yield put(setInstamojoSettingsError(error));
    }
  });
}

//plopSaga
export default function* rootSaga() {
  yield all([
    fork(SetWithdrawalPercentage),
    fork(getWithdrawalSettings),
    fork(saveImageCarousel),
    fork(getImageCarousel),
    fork(deleteImageCarousel),
    fork(addFaq),
    fork(getFaq),
    fork(deleteFaq),
    fork(getTermsAndConditions),
    fork(updateTermsAndCondition),
    fork(getPrivacyPolicys),
    fork(updatePrivacyPolicy),
    fork(getLanguage),
    fork(updateAppText),
    fork(getAppTextAstrologer),
    fork(updateAppTextAstrologer),
    fork(SetInstamojoSettings),
    fork(getInstamojoSettings),
    //plopExport
  ]);
}
