import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  getGstInvoiceError,
  getGstInvoiceSuccess
  //plopImportAction
} from "../actions/GstInvoiceActions.js";
import {
  GET_GST_INVOICE_INIT,
 
  //plopImportConstant
} from "redux/constants/GstInvoiceConstants.js";

import FirebaseService from "services/FirebaseService";

export function* GetGstInvoice() {
  yield takeEvery(GET_GST_INVOICE_INIT, function* () {
    try {
      const data = yield call(FirebaseService.fetchCollection, "Invoice");
        // Manual sorting by createdAt in descending order
        const sortedData = data.sort((a, b) => {
            const aSeconds = a.createdAt ? a.createdAt.seconds : 0;
            const bSeconds = b.createdAt ? b.createdAt.seconds : 0;
            return bSeconds - aSeconds;
          });
    
          // Manual filtering for accountStatus === "nonactive"
          const filteredData = sortedData.filter(element => element.gstType === "Astrologer");
      let changeTime = filteredData.map((element) => {
        var d = new Date(0);
        d.setUTCSeconds(element.createdAt.seconds);
        return {
          ...element,
          createdAt: `${d}`,
        };
      });

      console.log(
        "🚀 ~ file: GstInvoiceSaga.js ~ line 22 ~ changeTime ~ changeTime",
        changeTime
      );

      yield put(getGstInvoiceSuccess(changeTime));
    } catch (error) {
      console.log(
        "🚀 ~ file: GstInvoiceSaga.js ~ line 41 ~ yieldtakeEvery ~ error",
        error.message
      );
      yield put(getGstInvoiceError(error));
    }
  });
}



//plopSaga
export default function* rootSaga() {
  yield all([
    fork(GetGstInvoice),
    //plopExport
  ]);
}
