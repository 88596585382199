import {
    GET_GST_BIZ_INVOICE_INIT,
    GET_GST_BIZ_INVOICE_SUCCESS,
    GET_GST_BIZ_INVOICE_ERROR

} from '../constants/GstAstrocallInvoiceConstants.js';
export const getGstAstrocallInvoiceInit = () => ({
    type: GET_GST_BIZ_INVOICE_INIT,
});

export const getGstAstrocallInvoiceSuccess = (data) => ({
    type: GET_GST_BIZ_INVOICE_SUCCESS,
    data
});

export const getGstAstrocallInvoiceError = (error) => ({
    type: GET_GST_BIZ_INVOICE_ERROR,
    error
});