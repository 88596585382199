import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  getCancelledInvoiceError,
  getCancelledInvoiceSuccess,
  setCancelledInvoiceError,
  setCancelledInvoiceSuccess
  //plopImportAction
} from "../actions/CancelledInvoiceActions.js";
import {
    GET_CANCELLED_INVOICE_INIT,
    SET_CANCELLED_INVOICE_COMPLETE_INIT
  //plopImportConstant
} from "redux/constants/CancelledInvoiceConstants.js";

import FirebaseService from "services/FirebaseService";

export function* GetCancelledInvoice() {
  yield takeEvery(GET_CANCELLED_INVOICE_INIT, function* () {
    try {
      const data = yield call(FirebaseService.fetchCollection, "Invoice");
  
      // Manual sorting
      const sortedData = data.sort((a, b) => {
        const aSeconds = a.cancelled ? a.cancelled.seconds : 0;
        const bSeconds = b.cancelled ? b.cancelled.seconds : 0;
        return bSeconds - aSeconds;
      });
  
      // Manual filtering
      const filteredData = sortedData.filter(element => element.status === "Cancelled");
  
      let changeTime = filteredData.map((element) => {
        var d = new Date(0);
        if (element.cancelled && element.cancelled.seconds) {
          d.setUTCSeconds(element.cancelled.seconds);
        }
        return {
          ...element,
          cancelled: `${d}`,
        };
      });
  
      console.log("changeTime", changeTime);
  
      yield put(getCancelledInvoiceSuccess(changeTime));
    } catch (error) {
      console.log("error", error.message);
      yield put(getCancelledInvoiceError(error));
    }
  });
}


export function* setCancelledInvoice() {
    yield takeEvery(SET_CANCELLED_INVOICE_COMPLETE_INIT, function* (action) {
      try {
        yield call(FirebaseService.markCancelledInvoiceComplete, action.data);
        yield put(setCancelledInvoiceSuccess(action.data));
      } catch (error) {
        yield put(setCancelledInvoiceError(error));
      }
    });
  }
//plopSaga
export default function* rootSaga() {
  yield all([
    fork(GetCancelledInvoice),
    fork(setCancelledInvoice)
    //plopExport
  ]);
}
