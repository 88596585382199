import {
    SET_INACTIVE_DOCTOR_ACCOUNT_STATUS_INIT,
    SET_INACTIVE_DOCTOR_ACCOUNT_STATUS_SUCCESS,
    SET_INACTIVE_DOCTOR_ACCOUNT_STATUS_ERROR,
    
    INACTIVE_DOCTOR_FETCH_FAIL,
    INACTIVE_DOCTOR_FETCH_INIT,
    INACTIVE_DOCTOR_FETCH_SUCCESS
  } from "../constants/InactiveDoctor";
  
  export const fetchInactiveDoctorInit = () => ({
    type: INACTIVE_DOCTOR_FETCH_INIT,
  });
  
  export const fetchInactiveDoctorSuccess = (data) => ({
    type: INACTIVE_DOCTOR_FETCH_SUCCESS,
    data,
  });
  
  export const fetchInactiveDoctorFail = (err) => ({
    type: INACTIVE_DOCTOR_FETCH_FAIL,
    err,
  });
  
  
  
  

  
  export const setInactiveDoctorAccountStatusInit = (doctorId, status) => ({
    type: SET_INACTIVE_DOCTOR_ACCOUNT_STATUS_INIT,
    doctorId,
    status,
  });
  
  export const setInactiveDoctorAccountStatusSuccess = (doctorId, status) => ({
    type: SET_INACTIVE_DOCTOR_ACCOUNT_STATUS_SUCCESS,
    doctorId,
    status,
  });
  
  export const setInactiveDoctorAccountStatusError = (error) => ({
    type: SET_INACTIVE_DOCTOR_ACCOUNT_STATUS_ERROR,
    error,
  });
  