import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  getProblemError,
  getProblemSuccess,
  setProblemRequestAcceptActionSuccess,
  setProblemRequestAcceptActionError,
  setProblemRequestRejectActionSuccess,
  setProblemRequestRejectActionError,
  setProblemRequestRejectWithInvoiceActionSuccess,
  setProblemRequestRejectWithInvoiceActionError,
  getProblemAstrologerSuccess,
  getProblemAstrologerError,
  setProblemAstrologerRequestAcceptActionSuccess,
  setProblemAstrologerRequestAcceptActionError,
  setProblemAstrologerRequestRejectActionSuccess,
  setProblemAstrologerRequestRejectActionError,
  setProblemAstrologerRequestAcceptWithInvoiceActionSuccess,
  setProblemAstrologerRequestAcceptWithInvoiceActionError,
  //plopImportAction
} from "../actions/ProblemActions.js";
import {
  GET_PROBLEM_INIT,
  SET_PROBLEM_REQUEST_ACCEPT_INIT,
  SET_PROBLEM_REQUEST_REJECT_INIT,
  SET_PROBLEM_REQUEST_REJECT_WITH_INVOICE_INIT,
  GET_PROBLEM_ASTROLOGER_INIT,
  SET_PROBLEM_ASTROLOGER_REQUEST_ACCEPT_INIT,
  SET_PROBLEM_ASTROLOGER_REQUEST_REJECT_INIT,
  SET_PROBLEM_ASTROLOGER_REQUEST_ACCEPT_WITH_INVOICE_INIT
  //plopImportConstant
} from "redux/constants/ProblemConstants.js";

import FirebaseService from "services/FirebaseService";

export function* GetProblem() {
  yield takeEvery(GET_PROBLEM_INIT, function* () {
    try {
      const data = yield call(FirebaseService.fetchCollection, "Problem")
       // Manual sorting by createdAt in descending order
      const sortedData = data.sort((a, b) => {
        const aSeconds = a.call_time ? a.call_time.seconds : 0;
        const bSeconds = b.call_time ? b.call_time.seconds : 0;
        return bSeconds - aSeconds;
      });

      // Manual filtering for accountStatus === "nonactive"
      const filteredData = sortedData.filter(element => element.app === "Owner");

      let changeTime = filteredData.map((element) => {
        var d = new Date(0);
        if (element.call_time && element.call_time.seconds) {
          d.setUTCSeconds(element.call_time.seconds);
        }
        return {
          ...element,
          call_time: `${d}`,
        };
      });

      console.log(
        "🚀 ~ file: ProblemSaga.js ~ line 22 ~ changeTime ~ changeTime",
        changeTime
      );

      yield put(getProblemSuccess(changeTime));
    } catch (error) {
      console.log(
        "🚀 ~ file: ProblemSaga.js ~ line 41 ~ yieldtakeEvery ~ error",
        error.message
      );
      yield put(getProblemError(error));
    }
  });
}
export function* setProblemAcceptComplete() {
  yield takeEvery(SET_PROBLEM_REQUEST_ACCEPT_INIT, function* (action) {
    try {
      yield call(FirebaseService.markProblemAcceptRequestComplete, action.data);
      yield put(setProblemRequestAcceptActionSuccess(action.data));
    } catch (error) {
      yield put(setProblemRequestAcceptActionError(error));
    }
  });
}

export function* setProblemRejectComplete() {
  yield takeEvery(SET_PROBLEM_REQUEST_REJECT_INIT, function* (action) {
    try {
      yield call(FirebaseService.markProblemRejectRequestComplete, action.data);
      yield put(setProblemRequestRejectActionSuccess(action.data));
    } catch (error) {
      yield put(setProblemRequestRejectActionError(error));
    }
  });
}

export function* setProblemRejectWithInvoiceComplete() {
  yield takeEvery(SET_PROBLEM_REQUEST_REJECT_WITH_INVOICE_INIT, function* (action) {
    try {
      yield call(FirebaseService.markProblemRejectWithInvoiceRequestComplete, action.data);
      yield put(setProblemRequestRejectWithInvoiceActionSuccess(action.data));
    } catch (error) {
      yield put(setProblemRequestRejectWithInvoiceActionError(error));
    }
  });
}


export function* GetProblemAstrologer() {
  yield takeEvery(GET_PROBLEM_ASTROLOGER_INIT, function* () {
    try {
      const data = yield call(FirebaseService.fetchCollection, "Problem")
       // Manual sorting by createdAt in descending order
      const sortedData = data.sort((a, b) => {
        const aSeconds = a.call_time ? a.call_time.seconds : 0;
        const bSeconds = b.call_time ? b.call_time.seconds : 0;
        return bSeconds - aSeconds;
      });

      // Manual filtering for accountStatus === "nonactive"
      const filteredData = sortedData.filter(element => element.app === "Astrologer");

      let changeTime = filteredData.map((element) => {
        var d = new Date(0);
        if (element.call_time && element.call_time.seconds) {
          d.setUTCSeconds(element.call_time.seconds);
        }
        return {
          ...element,
          call_time: `${d}`,
        };
      });

      console.log(
        "🚀 ~ file: AstrologerProblemSaga.js ~ line 22 ~ changeTime ~ changeTime",
        changeTime
      );

      yield put(getProblemAstrologerSuccess(changeTime));
    } catch (error) {
      console.log(
        "🚀 ~ file: AstrologerProblemSaga.js ~ line 41 ~ yieldtakeEvery ~ error",
        error.message
      );
      yield put(getProblemAstrologerError(error));
    }
  });
}

export function* setProblemAstrologerAcceptComplete() {
  yield takeEvery(SET_PROBLEM_ASTROLOGER_REQUEST_ACCEPT_INIT, function* (action) {
    try {
      yield call(FirebaseService.markProblemAstrologerAcceptRequestComplete, action.data);
      yield put(setProblemAstrologerRequestAcceptActionSuccess(action.data));
    } catch (error) {
      yield put(setProblemAstrologerRequestAcceptActionError(error));
    }
  });
}

export function* setProblemAstrologerRejectComplete() {
  yield takeEvery(SET_PROBLEM_ASTROLOGER_REQUEST_REJECT_INIT, function* (action) {
    try {
      yield call(FirebaseService.markProblemAstrologerRejectRequestComplete, action.data);
      yield put(setProblemAstrologerRequestRejectActionSuccess(action.data));
    } catch (error) {
      yield put(setProblemAstrologerRequestRejectActionError(error));
    }
  });
}

export function* setProblemAstrologerAcceptWithInvoiceComplete() {
  yield takeEvery(SET_PROBLEM_ASTROLOGER_REQUEST_ACCEPT_WITH_INVOICE_INIT, function* (action) {
    try {
      yield call(FirebaseService.markProblemAstrologerAcceptWithInvoiceRequestComplete, action.data);
      yield put(setProblemAstrologerRequestAcceptWithInvoiceActionSuccess(action.data));
    } catch (error) {
      yield put(setProblemAstrologerRequestAcceptWithInvoiceActionError(error));
    }
  });
}
//plopSaga
export default function* rootSaga() {
  yield all([
    fork(GetProblem),
    fork(setProblemAcceptComplete),
    fork(setProblemRejectComplete),
    fork(setProblemRejectWithInvoiceComplete),
    fork(GetProblemAstrologer),
    fork(setProblemAstrologerAcceptComplete),
    fork(setProblemAstrologerRejectComplete),
    fork(setProblemAstrologerAcceptWithInvoiceComplete),
    //plopExport
  ]);
}
