const FirebaseConfig = {
  apiKey: "AIzaSyAUfQZoz0_SGRUrPyi3eyFwlpnwJtl49Pk",
  authDomain: "astro-call-4843f.firebaseapp.com",
  databaseURL: "https://astro-call-4843f-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "astro-call-4843f",
  storageBucket: "astro-call-4843f.appspot.com",
  messagingSenderId: "918003184772",
  appId: "1:918003184772:web:c15a2b78732d65856f3ed1",
  measurementId: "G-PENKESQM7R"

};

export default FirebaseConfig;
