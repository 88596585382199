import {
    GET_CONTACT_US_INIT,
    GET_CONTACT_US_SUCCESS,
    GET_CONTACT_US_ERROR
} from '../constants/ContactUsConstants.js';

export const getContactUsInit = () => ({
    type: GET_CONTACT_US_INIT,
});

export const getContactUsSuccess = (data) => ({
    type: GET_CONTACT_US_SUCCESS,
    data
});

export const getContactUsError = (error) => ({
    type: GET_CONTACT_US_ERROR,
    error
});