export const SET_WITHDRAWAL_PERCENTAGE_INIT = "SET_WITHDRAWAL_PERCENTAGE_INIT";
export const SET_WITHDRAWAL_PERCENTAGE_SUCCESS = "SET_WITHDRAWAL_PERCENTAGE_SUCCESS";
export const SET_WITHDRAWAL_PERCENTAGE_ERROR = "SET_WITHDRAWAL_PERCENTAGE_ERROR";
//plopImport
export const UPDATE_APP_TEXT_ASTROLOGER_INIT = "UPDATE_APP_TEXT_ASTROLOGER_INIT";
export const UPDATE_APP_TEXT_ASTROLOGER_SUCCESS = "UPDATE_APP_TEXT_ASTROLOGER_SUCCESS";
export const UPDATE_APP_TEXT_ASTROLOGER_ERROR = "UPDATE_APP_TEXT_ASTROLOGER_ERROR";
export const GET_APP_TEXT_ASTROLOGER_INIT = "GET_APP_TEXT_ASTROLOGER_INIT";
export const GET_APP_TEXT_ASTROLOGER_SUCCESS = "GET_APP_TEXT_ASTROLOGER_SUCCESS";
export const GET_APP_TEXT_ASTROLOGER_ERROR = "GET_APP_TEXT_ASTROLOGER_ERROR";
export const UPDATE_APP_TEXT_INIT = "UPDATE_APP_TEXT_INIT";
export const UPDATE_APP_TEXT_SUCCESS = "UPDATE_APP_TEXT_SUCCESS";
export const UPDATE_APP_TEXT_ERROR = "UPDATE_APP_TEXT_ERROR";
export const GET_LANGUAGE_INIT = "GET_LANGUAGE_INIT";
export const GET_LANGUAGE_SUCCESS = "GET_LANGUAGE_SUCCESS";
export const GET_LANGUAGE_ERROR = "GET_LANGUAGE_ERROR";
export const UPDATE_TERMS_AND_CONDITION_INIT = "UPDATE_TERMS_AND_CONDITION_INIT";
export const UPDATE_TERMS_AND_CONDITION_SUCCESS = "UPDATE_TERMS_AND_CONDITION_SUCCESS";
export const UPDATE_TERMS_AND_CONDITION_ERROR = "UPDATE_TERMS_AND_CONDITION_ERROR";
export const GET_TERMS_AND_CONDITIONS_INIT = "GET_TERMS_AND_CONDITIONS_INIT";
export const GET_TERMS_AND_CONDITIONS_SUCCESS = "GET_TERMS_AND_CONDITIONS_SUCCESS";
export const GET_TERMS_AND_CONDITIONS_ERROR = "GET_TERMS_AND_CONDITIONS_ERROR";
export const DELETE_FAQ_INIT = "DELETE_FAQ_INIT";
export const DELETE_FAQ_SUCCESS = "DELETE_FAQ_SUCCESS";
export const DELETE_FAQ_ERROR = "DELETE_FAQ_ERROR";
export const GET_FAQ_INIT = "GET_FAQ_INIT";
export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
export const GET_FAQ_ERROR = "GET_FAQ_ERROR";
export const ADD_FAQ_INIT = "ADD_FAQ_INIT";
export const ADD_FAQ_SUCCESS = "ADD_FAQ_SUCCESS";
export const ADD_FAQ_ERROR = "ADD_FAQ_ERROR";
export const DELETE_IMAGE_CAROUSEL_INIT = "DELETE_IMAGE_CAROUSEL_INIT";
export const DELETE_IMAGE_CAROUSEL_SUCCESS = "DELETE_IMAGE_CAROUSEL_SUCCESS";
export const DELETE_IMAGE_CAROUSEL_ERROR = "DELETE_IMAGE_CAROUSEL_ERROR";
export const GET_IMAGE_CAROUSEL_INIT = "GET_IMAGE_CAROUSEL_INIT";
export const GET_IMAGE_CAROUSEL_SUCCESS = "GET_IMAGE_CAROUSEL_SUCCESS";
export const GET_IMAGE_CAROUSEL_ERROR = "GET_IMAGE_CAROUSEL_ERROR";
export const SAVE_IMAGE_CAROUSEL_INIT = "SAVE_IMAGE_CAROUSEL_INIT";
export const SAVE_IMAGE_CAROUSEL_SUCCESS = "SAVE_IMAGE_CAROUSEL_SUCCESS";
export const SAVE_IMAGE_CAROUSEL_ERROR = "SAVE_IMAGE_CAROUSEL_ERROR";
export const GET_WITHDRAWAL_SETTINGS_INIT = "GET_WITHDRAWAL_SETTINGS_INIT";
export const GET_WITHDRAWAL_SETTINGS_SUCCESS = "GET_WITHDRAWAL_SETTINGS_SUCCESS";
export const GET_WITHDRAWAL_SETTINGS_ERROR = "GET_WITHDRAWAL_SETTINGS_ERROR";
export const UPDATE_PRIVACY_POLICY_INIT = "UPDATE_TERMS_AND_CONDITION_INIT";
export const UPDATE_PRIVACY_POLICY_SUCCESS = "UPDATE_TERMS_AND_CONDITION_SUCCESS";
export const UPDATE_PRIVACY_POLICY_ERROR = "UPDATE_TERMS_AND_CONDITION_ERROR";
export const GET_PRIVACY_POLICYS_INIT = "GET_PRIVACY_POLICYS_INIT";
export const GET_PRIVACY_POLICYS_SUCCESS = "GET_PRIVACY_POLICYS_SUCCESS";
export const GET_PRIVACY_POLICYS_ERROR = "GET_PRIVACY_POLICYS_ERROR";


export const GET_INSTAMOJO_SETTINGS_INIT = "GET_INSTAMOJO_SETTINGS_INIT";
export const GET_INSTAMOJO_SETTINGS_SUCCESS = "GET_INSTAMOJO_SETTINGS_SUCCESS";
export const GET_INSTAMOJO_SETTINGS_ERROR = "GET_INSTAMOJO_SETTINGS_ERROR";

export const SET_INSTAMOJO_SETTINGS_INIT = "SET_INSTAMOJO_SETTINGS_INIT";
export const SET_INSTAMOJO_SETTINGS_SUCCESS = "SET_INSTAMOJO_SETTINGS_SUCCESS";
export const SET_INSTAMOJO_SETTINGS_ERROR = "SET_INSTAMOJO_SETTINGS_ERROR";