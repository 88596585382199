import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  getContactUsError,
  getContactUsSuccess,
  //plopImportAction
} from "../actions/ContactUsActions.js";
import {
  GET_CONTACT_US_INIT,
  //plopImportConstant
} from "redux/constants/ContactUsConstants.js";

import FirebaseService from "services/FirebaseService";

export function* GetContactUs() {
  yield takeEvery(GET_CONTACT_US_INIT, function* () {
    try {
      const data = yield call(FirebaseService.fetchCollection, "ContactUs", {
        sort: {
          attribute: "dateCreated",
          order: "desc",
        },
      });
      let changeTime = data.map((element) => {
        var d = new Date(0);
        d.setUTCSeconds(element.dateCreated.seconds);
        return {
          ...element,
          createdAt: `${d}`,
        };
      });
      yield put(getContactUsSuccess(changeTime));
    } catch (error) {
      yield put(getContactUsError(error));
    }
  });
}
//plopSaga
export default function* rootSaga() {
  yield all([
    fork(GetContactUs),
    //plopExport
  ]);
}
