import {
    SET_CANCELLED_INVOICE_COMPLETE_INIT,
    SET_CANCELLED_INVOICE_COMPLETE_SUCCESS,
    SET_CANCELLED_INVOICE_COMPLETE_ERROR,
    GET_CANCELLED_INVOICE_INIT,
    GET_CANCELLED_INVOICE_SUCCESS,
    GET_CANCELLED_INVOICE_ERROR, 
  } from "../constants/CancelledInvoiceConstants.js";
  
  const initialState = {
    data: [],
    loading: false,
    error: null,
    success: false,
    delete: false,
  };
  
  const cancelledinvoice = (state = initialState, action) => {
    switch (action.type) {
      case GET_CANCELLED_INVOICE_INIT:
        return { ...state, loading: true };
      case GET_CANCELLED_INVOICE_SUCCESS:
        return { ...state, success: true, data: action.data, loading: false };
      case GET_CANCELLED_INVOICE_ERROR:
        return { ...state, error: action.error, loading: false };

        case SET_CANCELLED_INVOICE_COMPLETE_INIT:
      return { ...state, loading: true };
    case SET_CANCELLED_INVOICE_COMPLETE_SUCCESS:
      let objIndex = state.data.findIndex((obj) => obj.id === action.data.id);
      let newArray = state.data;
      newArray[objIndex].action = "complete";
      return { ...state, success: true, data: newArray, loading: false };
    case SET_CANCELLED_INVOICE_COMPLETE_ERROR:
      return { ...state, error: action.error, loading: false };
      default:
        return state;
    }
  };
  
  export default cancelledinvoice;
  