import {
    GET_INVOICE_INIT,
    GET_INVOICE_SUCCESS,
    GET_INVOICE_ERROR,
    GET_BIZ_INVOICE_INIT,
    GET_BIZ_INVOICE_SUCCESS,
    GET_BIZ_INVOICE_ERROR
  } from "../constants/AstrocallInvoiceConstants.js";
  
  const initialState = {
    data: [],
    loading: false,
    error: null,
    success: false,
    delete: false,
  };
  
  const astrocallinvoice = (state = initialState, action) => {
    switch (action.type) {
        case GET_BIZ_INVOICE_INIT:
          return { ...state, loading: true };
        case GET_BIZ_INVOICE_SUCCESS:
          return { ...state, success: true, data: action.data, loading: false };
        case GET_BIZ_INVOICE_ERROR:
          return { ...state, error: action.error, loading: false };  
      //plopImport
      default:
        return state;
    }
  };
  
  export default astrocallinvoice;
  