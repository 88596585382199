import {
  firebase,
  firestore,
  functions,
  auth,
  db,
  storageRef,
  googleAuthProvider,
  facebookAuthProvider,
} from "auth/FirebaseAuth";
import { getStorage, ref, deleteObject } from "firebase/storage";
//import firestore from 'FirebaseConfig.js';
const FirebaseService = {};

// FirebaseService.signInEmailRequest = async (email, password) =>
//   await auth
//     .signInWithEmailAndPassword(email, password)
//     .then((user) => user)
//     .catch((err) => err);

FirebaseService.signInEmailRequest = async (email, password) =>
  await auth
    .signInWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((err) => err);


FirebaseService.signOutRequest = async () =>
  await auth
    .signOut()
    .then((user) => user)
    .catch((err) => err);

FirebaseService.signInGoogleRequest = async () =>
  await auth
    .signInWithPopup(googleAuthProvider)
    .then((user) => user)
    .catch((err) => err);

FirebaseService.signInFacebookRequest = async () =>
  await auth
    .signInWithPopup(facebookAuthProvider)
    .then((user) => user)
    .catch((err) => err);

FirebaseService.signUpEmailRequest = async (email, password) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((err) => err);

const getFirestoreRef = (path) => firebase.firestore().collection(path);

FirebaseService.fetchDocument = async (collection, id) => {
  const document = await getFirestoreRef(collection).doc(id).get();
  if (!document.exists) return null;
  return { id: document.id, ...document.data() };
};

FirebaseService.fetchCollection = async (collection, options = {}) => {
  const data = [];
  let baseQuery = getFirestoreRef(collection);

  if (options.queries) {
    const { queries } = options;

    queries.forEach(({ attribute, operator, value }) => {
      if (attribute === "uid")
        attribute = firebase.firestore.FieldPath.documentId();
      baseQuery = baseQuery.where(attribute, operator, value);
    });
  }

  if (options.sort) {
    const { attribute, order } = options.sort;
    baseQuery = baseQuery.orderBy(attribute, order);
  }

  (await baseQuery.get()).forEach((doc) => {
    data.push({ id: doc.id, ...doc.data() });
  });

  return data;
};

FirebaseService.uploadImage = async (fileName, file) => {
  console.log("filename : " + fileName);
  let url = await storageRef.ref("uploads").child(fileName).put(file);
  return url.ref.getDownloadURL();
};

FirebaseService.addDoctorCategory = async (categoryName, iconUrl) => {
  try {
    await db.collection("DoctorCategory").add({
      categoryName,
      iconUrl,
    });
  } catch (error) {
    throw error;
  }
};

FirebaseService.removeDoctorCategory = async (categoryId) => {
  try {
    await db.collection("DoctorCategory").doc(categoryId).delete();
  } catch (error) {
    throw error;
  }
};

FirebaseService.editDoctorCategory = async (id, categoryName, iconUrl) => {
  try {
    if (iconUrl == null) {
      await db.collection("DoctorCategory").doc(id).update({
        categoryName,
      });
    } else {
      await db.collection("DoctorCategory").doc(id).set({
        categoryName,
        iconUrl,
      });
    }
  } catch (error) {
    throw error;
  }
};

FirebaseService.removeTopRatedDoctor = async (topRatedId) => {
  try {
    let snapshot = await db
      .collection("TopRatedDoctor")
      .where("doctorId", "==", topRatedId)
      .get();
    await snapshot.docs.at(0).ref.delete();
  } catch (error) {
    console.log(
      "🚀 ~ file: FirebaseService.js ~ line 124 ~ FirebaseService.removeTopRatedDoctor= ~ error",
      error
    );

    throw error;
  }
};

FirebaseService.addTopRatedDoctor = async (doctorId) => {
  try {
    await db.collection("TopRatedDoctor").add({ doctorId });
  } catch (error) {
    throw error;
  }
};

FirebaseService.deleteDoctorAccount = async (doctorId) => {
  try {
    var addMessage = firebase.functions().httpsCallable("deleteDoctor");
    await addMessage({ doctorId: doctorId });
    console.log("delete doctor");
  } catch (error) {
    console.log(
      "🚀 ~ file: FirebaseService.js ~ line 160 ~ FirebaseService.deleteDoctorAccount ~ error",
      error
    );
    throw error;
  }
};
FirebaseService.deleteUser = async (userId) => {
  try {
    var addMessage = firebase.functions().httpsCallable("deleteUser");
    await addMessage({ userId: userId });
    console.log("delete user");
  } catch (error) {
    console.log(
      "🚀 ~ file: FirebaseService.js ~ line 160 ~ FirebaseService.deleteDoctorAccount ~ error",
      error
    );
    throw error;
  }
};

FirebaseService.countCollection = async (documentName) => {
  let size = await db
    .collection(documentName)
    .get()
    .then((snap) => {
      return snap.size; // will return the collection size
    });
  return size;
};

FirebaseService.setPercentage = async (data) => {
  console.log(data);
  await db
    .collection("Settings")
    .doc("withdrawSetting")
    .set({ percentage: data.data.percentage, cgst: data.data.cgst, sgst: data.data.sgst,igst: data.data.igst, tcs: data.data.tcs, tds: data.data.tds, nogstTds: data.data.nogstTds, sacAstrologer: data.data.sacAstrologer,  sacAstrocall: data.data.sacAstrocall, gstno: data.data.gstno,name: data.data.name, address: data.data.address,pin: data.data.pin, country: data.data.country, state: data.data.state, stateCode: data.data.stateCode, advhour: data.data.advhour,advminute :data.data.advminute });

  };

FirebaseService.getWithdrawalSettings = async () => {
  try {
    let snapshot = await db.collection("Settings").doc("withdrawSetting").get();
    if (!snapshot.exists) return {};
    return snapshot.data();
  } catch (error) {
    console.log(
      "🚀 ~ file: FirebaseService.js ~ line 206 ~ FirebaseService.getWithdrawalSettings= ~ error",
      error
    );
    throw error;
  }
};

FirebaseService.getImageCarousel = async () => {
  try {
    let data = [];
    let snapshot = await db
      .collection("Settings")
      .doc("imageCarousel")
      .collection("listImage")
      .get();
    console.log(
      "🚀 ~ file: FirebaseService.js ~ line 213 ~ FirebaseService.getImageCarousel= ~ snapshot",
      snapshot.docs
    );
    snapshot.docs.forEach((doc) => {
      data.push({ id: doc.id, ...doc.data() });
    });
    return data;
  } catch (error) {
    console.log(
      "🚀 ~ file: FirebaseService.js ~ line 212 ~ FirebaseService.getImageCarousel= ~ error",
      error
    );
    throw error;
  }
};
FirebaseService.removeImageCarousel = async (data) => {
  console.log(
    "🚀 ~ file: FirebaseService.js ~ line 232 ~ FirebaseService.removeImageCarousel= ~ data",
    data
  );
  try {
    await db
      .collection("Settings")
      .doc("imageCarousel")
      .collection("listImage")
      .doc(data.data.imageId)
      .delete();

    let picRef = storageRef.refFromURL(data.data.imageUrl);
    picRef.delete();
  } catch (error) {
    throw error;
  }
};
FirebaseService.saveImageCarouselData = async (data) => {
  try {
    await db
      .collection("Settings")
      .doc("imageCarousel")
      .collection("listImage")
      .add({
        imageUrl: data.imageUrl,
        fileName: data.fileName,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      });
  } catch (error) {
    console.log(
      "🚀 ~ file: FirebaseService.js ~ line 231 ~ FirebaseService.saveImageCarouselData= ~ error",
      error
    );
    throw error;
  }
};

FirebaseService.markWithdrawalRequestPending = async (record) => {
  try {
    console.log(record.id);
    //console.log(newField);
    
     if(record.status === 'complete'){
      console.log("completed -> pending");
      
    await db
      .collection("WithdrawRequest")
      .doc(record.id)
      .update({ status: "pending", transactionId : "" });

    let transactionSnapshot = await db
      .collection("Transaction")
      .where("withdrawRequestId", "==", record.id)
      .get();
    if (transactionSnapshot.empty) return;
    transactionSnapshot.docs[0].ref.update({ status: "pending" });
    }
  } catch (error) {
    console.log(
      "🚀 ~ file: FirebaseService.js ~ line 224 ~ FirebaseService.markWithdrawalRequestComplete ~ error",
      error
    );

    throw error;
  }
};


FirebaseService.markWithdrawalRequestComplete = async (record) => {
  try {
    console.log(record);
    const transactionId = record.additionalInfo;
    //console.log(newField);
     if(record.status === 'pending'){
      console.log("pending -> complete");
    await db
      .collection("WithdrawRequest")
      .doc(record.id)
      .update({ status: "complete", transactionId : transactionId });

    let transactionSnapshot = await db
      .collection("Transaction")
      .where("withdrawRequestId", "==", record.id)
      .get();
    if (transactionSnapshot.empty) return;
    transactionSnapshot.docs[0].ref.update({ status: "success" });
     }
  } catch (error) {
    console.log(
      "🚀 ~ file: FirebaseService.js ~ line 224 ~ FirebaseService.markWithdrawalRequestComplete ~ error",
      error
    );

    throw error;
  }
};
FirebaseService.markProblemAcceptRequestComplete = async (record) => {
  try {
    console.log(record);
    //var addMessage = firebase.functions().httpsCallable("AutoAstrocallinvoice");
    //const response = await addMessage({ timeSlotId: '7zVMN3DrGuKBtL0oH6tW' });
    //console.log("delete doctor");
    // if (response.data.success) {
    //   // Access the invoice ID
    //   const invoiceId = response.data.invoiceId;
    //   console.log("Invoice ID:", invoiceId);
     
    //   // Handle other data if needed
    //   // ...
    //   } else {
    //   // Handle any errors or failure
    //   console.error("Invoice generation failed");
    //   }
    //console.log(record);
    const currentDate = firebase.firestore.FieldValue.serverTimestamp();
    const referenceIdRef = record.referenceIdRef1;
    console.log(referenceIdRef);
    const selectedDateRef = record.selectedDateRef1;
    const descriptionRef = record.descriptionRef1;
    console.log("problem working");
    //  if(record.status === 'pending'){
      //console.log("pending -> complete");
    await db
      .collection("Problem")
      .doc(record.id)
      .update({ paymentRefId : referenceIdRef,paymentDate:selectedDateRef, acceptedDescription:descriptionRef,actionTimestamp: currentDate,status:"Accepted"});

    // let transactionSnapshot = await db
    //   .collection("Transaction")
    //   .where("withdrawRequestId", "==", record.id)
    //   .get();
    // if (transactionSnapshot.empty) return;
    // transactionSnapshot.docs[0].ref.update({ status: "success" });
    //  }
  } catch (error) {
    console.log(
      "🚀 ~ file: FirebaseService.js ~ line 224 ~ FirebaseService.markWithdrawalRequestComplete ~ error",
      error
    );

    throw error;
  }
};
FirebaseService.markProblemRejectRequestComplete = async (record) => {
  //console.log("aswany");

  
  try {
    console.log(record);
    const currentDate = firebase.firestore.FieldValue.serverTimestamp();
    const descriptionRef = record.descriptionRef;
    await db
      .collection("Problem")
      .doc(record.id)
      .update({ rejectedDescription:descriptionRef,actionTimestamp: currentDate,status:"Rejected"});
  } catch (error) {
    console.log(
      "🚀 ~ file: FirebaseService.js ~ line 224 ~ FirebaseService.markWithdrawalRequestComplete ~ error",
      error
    );

    throw error;
  }
};

FirebaseService.markProblemRejectWithInvoiceRequestComplete = async (record) => {
  try {
    console.log(record.timeSlot);
    var addMessage1 = firebase.functions().httpsCallable("AutoAstrocallinvoice");
    const response = await addMessage1({ timeSlotId: record.timeSlot });
    console.log("Add problem invoice"+response.data.success);
    if (response.data.success === true) {
      console.log(response.data.invoiceId);
      // Access the invoice ID
      const invoiceId = response.data.invoiceId;
      // if(invoiceId !== '')
      // {
        console.log("Invoice ID if :", invoiceId);
     
        console.log(record);
        const currentDate = firebase.firestore.FieldValue.serverTimestamp();
        const descriptionRef = record.descriptionRef;
        await db
          .collection("Problem")
          .doc(record.id)
          .update({ rejectWithInvoiceDescription:descriptionRef,actionTimestamp: currentDate,invoiceId:invoiceId,status:"RejectedwithInvoice",rejectInvoiceStatus:"1"});

      // }
      // else
      // {
        

        // }
      
      } else {
         alert("Already invoice was generated" );
      //  await db
      // .collection("Problem")
      // .doc(record.id)
      // .update({status:"Pending",rejectInvoiceStatus:"0"});
      // Handle any errors or failure
      // alert("Invoice Already created.. So please reject");
      
      
      }
    
  } catch (error) {
    console.log(
      "🚀 ~ file: FirebaseService.js ~ line 224 ~ FirebaseService.markWithdrawalRequestComplete ~ error",
      error
    );

    throw error;
  }
};
  
// problem by astrologer //
FirebaseService.markProblemAstrologerAcceptRequestComplete = async (record) => {
  try {
    console.log(record);
    const currentDate = firebase.firestore.FieldValue.serverTimestamp();
  
    const descriptionAstrologer1 = record.descriptionAstrologer1;
    console.log("problem working"+descriptionAstrologer1 );
    
    await db
      .collection("Problem")
      .doc(record.id)
      .update({ acceptedDescription:descriptionAstrologer1,actionTimestamp: currentDate,status:"Accepted"});

  } catch (error) {
    console.log(
      "🚀 ~ file: FirebaseService.js ~ line 224 ~ FirebaseService.markProblemAcceptRequestComplete ~ error",
      error
    );

    throw error;
  }
};


FirebaseService.markProblemAstrologerRejectRequestComplete = async (record) => {
  //console.log("aswany");

  
  try {
    console.log(record);
    const currentDate = firebase.firestore.FieldValue.serverTimestamp();
    const descriptionRef = record.descriptionRef;
    await db
      .collection("Problem")
      .doc(record.id)
      .update({ rejectedDescription:descriptionRef,actionTimestamp: currentDate,status:"Rejected"});
  } catch (error) {
    console.log(
      "🚀 ~ file: FirebaseService.js ~ line 224 ~ FirebaseService.markWithdrawalRequestComplete ~ error",
      error
    );

    throw error;
  }
};

FirebaseService.markProblemAstrologerAcceptWithInvoiceRequestComplete = async (record) => {
  try {
    console.log(record.timeSlot);
    var addMessage1 = firebase.functions().httpsCallable("AutoAstrocallinvoice");
    const response = await addMessage1({ timeSlotId: record.timeSlot });
    console.log("Add problem invoice"+response.data.success);
    if (response.data.success === true) {
      console.log(response.data.invoiceId);
      // Access the invoice ID
      const invoiceId = response.data.invoiceId;
      // if(invoiceId !== '')
      // {
        console.log("Invoice ID if :", invoiceId);
     
        console.log(record);
        const currentDate = firebase.firestore.FieldValue.serverTimestamp();
        const descriptionRef = record.descriptionRef;
        await db
          .collection("Problem")
          .doc(record.id)
          .update({ acceptWithInvoiceDescription:descriptionRef,actionTimestamp: currentDate,invoiceId:invoiceId,status:"AcceptedwithInvoice",rejectInvoiceStatus:"1"});

     
      } else {
         alert("Already invoice was generated" );
    
      
      
      }
    
  } catch (error) {
    console.log(
      "🚀 ~ file: FirebaseService.js ~ line 224 ~ FirebaseService.markWithdrawalRequestComplete ~ error",
      error
    );

    throw error;
  }
};
  

FirebaseService.setDoctorAccountStatus = async (record, status) => {
  try {
    await db
      .collection("Doctors")
      .doc(record)
      .update({ accountStatus: status });
  } catch (error) {
    console.log(
      "🚀 ~ file: FirebaseService.js ~ line 240 ~ FirebaseService.setDoctorAccountStatus= ~ error",
      error
    );

    throw error;
  }
};
FirebaseService.addFaq = async (question, answer) => {
  try {
    let faqRef = await db.collection("Faq").add({
      question: question,
      answer: answer,
    });
    return faqRef.id;
  } catch (error) {
    throw error;
  }
};
FirebaseService.deleteFaq = async (faqId) => {
  try {
    await db.collection("Faq").doc(faqId).delete();
  } catch (error) {
    throw error;
  }
};
FirebaseService.updateTermsAndConditions = async (data) => {
  try {
    await db.collection("TermsAndCondition").doc(data.id).update(data);
    //console.log("Success update TermsAndCondition");
  } catch (error) {
   // console.log("Error update TermsAndCondition");
    throw error;
  }
};
FirebaseService.updateprivacyPolicys = async (data) => {
  console.log(data);
  try {
    await db.collection("PrivacyPolicy").doc(data.id).update(data);
    //console.log("Success update privacy");
  } catch (error) {
    //console.log("Error update privacy");
    throw error;
  }
};
FirebaseService.getLanguage = async () => {
  try {
    var languageData = await db
      .collection("Settings")
      .doc("clientLanguage")
      .get();
    return languageData.data();
  } catch (error) {
    throw error;
  }
};
FirebaseService.getAppTextAstrologer = async () => {
  try {
    var languageData = await db
      .collection("Settings")
      .doc("astrologerLanguage")
      .get();
    return languageData.data();
  } catch (error) {
    throw error;
  }
};
FirebaseService.updateAppText = async (data) => {
  console.log(
    "🚀 ~ file: FirebaseService.js ~ line 344 ~ FirebaseService.updateAppText= ~ data",
    data
  );
  try {
    await db.collection("Settings").doc("clientLanguage").update(data);
    await db
      .collection("Settings")
      .doc("languageVersion")
      .update({ version: firebase.firestore.FieldValue.increment(1) });
  } catch (error) {
    throw error;
  }
};
FirebaseService.updateAppTextAstrologers = async (data) => {
  console.log(
    "🚀 ~ file: FirebaseService.js ~ line 344 ~ FirebaseService.updateAppText= ~ data",
    data
  );
  try {
    await db.collection("Settings").doc("astrologerLanguage").update(data);
    await db
      .collection("Settings")
      .doc("languageVersion")
      .update({ version: firebase.firestore.FieldValue.increment(1) });
  } catch (error) {
    throw error;
  }
};


FirebaseService.getRazorpaySettings = async () => {
  
  try {
    let snapshot = await db.collection("Settings").doc("razorPay").get();
    console.log(
      "🚀 ~ file: FirebaseService.js ~ line 206 ~ FirebaseService.getRazorpaySettings= ~ snapshot",
      snapshot.data()
    );
    if (!snapshot.exists) return {};
    return snapshot.data();
  } catch (error) {
    console.log(
      "🚀 ~ file: FirebaseService.js ~ line 206 ~ FirebaseService.getRazorpaySettings= ~ error",
      error
    );
    throw error;
  }
};

FirebaseService.setRazorpay = async (data) => {
  console.log(data);
  await db
    .collection("Settings")
    .doc("razorPay")
    .set({ liveKey: data.data.liveKey, liveKeysecret: data.data.liveKeysecret, testKey: data.data.testKey, testKeysecret: data.data.testKeysecret,  mode: data.data.mode });


  };


  FirebaseService.markCancelledInvoiceComplete = async (record) => {
    try {
      await db
        .collection("Invoice")
        .doc(record.id)
        .update({ action: "complete" });
  
      
    } catch (error) {
      console.log(
        "🚀 ~ file: FirebaseService.js ~ line 2249~ FirebaseService.markCancelledInvoiceComplete ~ error",
        error
      );
  
      throw error;
    }
  };


  FirebaseService.getInstamojoSettings = async () => {
  
    try {
      let snapshot = await db.collection("Settings").doc("instaMojo").get();
      // console.log(
      //   "🚀 ~ file: FirebaseService.js ~ line 206 ~ FirebaseService.getRazorpaySettings= ~ snapshot",
      //   snapshot.data()
      // );
      if (!snapshot.exists) return {};
      return snapshot.data();
    } catch (error) {
      console.log(
        "🚀 ~ file: FirebaseService.js ~ line 206 ~ FirebaseService.getInstamojoSettings= ~ error",
        error
      );
      throw error;
    }
  };
FirebaseService.setInstamojo = async (data) => {
  console.log("dINCYYYYYYYYYYYYYY");
    console.log(data);
    await db
      .collection("Settings")
      .doc("instaMojo")
      //.set({ liveUrl: data.data.liveUrl, testUrl: data.data.testUrl, instaMode: data.data.instaMode,instaState: data.data.instaState,liveKey: data.data.liveKey, testKey: data.data.testKey, liveKeysecret: data.data.liveKeysecret, testKeysecret: data.data.testKeysecret,razorMode: data.data.razorMode, razorState: data.data.razorState });
      .set({ instaState: data.data.instaState,liveKey: data.data.liveKey, liveKeysecret: data.data.liveKeysecret,testKey: data.data.testKey, testKeysecret: data.data.testKeysecret, razorMode: data.data.razorMode, razorState: data.data.razorState });
    };
    FirebaseService.changeInactiveDoctor = async (doctorId) => {
      try {
        //console.log("doctorId"+doctorId);
        await db.collection("Doctors").doc(doctorId).update({ accountStatus: "active" });;
      } catch (error) {
        throw error;
      }
    };
export default FirebaseService;
