import {
    GET_INVOICE_INIT,
    GET_INVOICE_SUCCESS,
    GET_INVOICE_ERROR
    
} from '../constants/InvoiceConstants.js';

export const getInvoiceInit = () => ({
    type: GET_INVOICE_INIT,
});

export const getInvoiceSuccess = (data) => ({
    type: GET_INVOICE_SUCCESS,
    data
});

export const getInvoiceError = (error) => ({
    type: GET_INVOICE_ERROR,
    error
});