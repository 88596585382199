import {
    GET_BIZ_INVOICE_INIT,
    GET_BIZ_INVOICE_SUCCESS,
    GET_BIZ_INVOICE_ERROR

} from '../constants/AstrocallInvoiceConstants.js';
export const getAstrocallInvoiceInit = () => ({
    type: GET_BIZ_INVOICE_INIT,
});

export const getAstrocallInvoiceSuccess = (data) => ({
    type: GET_BIZ_INVOICE_SUCCESS,
    data
});

export const getAstrocallInvoiceError = (error) => ({
    type: GET_BIZ_INVOICE_ERROR,
    error
});