import {
    SET_CANCELLED_INVOICE_COMPLETE_INIT,
    SET_CANCELLED_INVOICE_COMPLETE_SUCCESS,
    SET_CANCELLED_INVOICE_COMPLETE_ERROR,
    GET_CANCELLED_INVOICE_INIT,
    GET_CANCELLED_INVOICE_SUCCESS,
    GET_CANCELLED_INVOICE_ERROR,
    
} from '../constants/CancelledInvoiceConstants.js';

export const getCancelledInvoiceInit = () => ({
    type: GET_CANCELLED_INVOICE_INIT,
});

export const getCancelledInvoiceSuccess = (data) => ({
    type: GET_CANCELLED_INVOICE_SUCCESS,
    data
});

export const getCancelledInvoiceError = (error) => ({
    type: GET_CANCELLED_INVOICE_ERROR,
    error
});

export const setCancelledInvoiceInit = (data) => ({
    type: SET_CANCELLED_INVOICE_COMPLETE_INIT,
    data,
});

export const setCancelledInvoiceSuccess = (data) => ({
    type: SET_CANCELLED_INVOICE_COMPLETE_SUCCESS,
    data,
});

export const setCancelledInvoiceError = (error) => ({
    type: SET_CANCELLED_INVOICE_COMPLETE_ERROR,
    error,
});