import {
    GET_GST_INVOICE_INIT,
    GET_GST_INVOICE_SUCCESS,
    GET_GST_INVOICE_ERROR,
    
} from '../constants/GstInvoiceConstants.js';

export const getGstInvoiceInit = () => ({
    type: GET_GST_INVOICE_INIT,
});

export const getGstInvoiceSuccess = (data) => ({
    type: GET_GST_INVOICE_SUCCESS,
    data
});

export const getGstInvoiceError = (error) => ({
    type: GET_GST_INVOICE_ERROR,
    error
});