import { all } from "redux-saga/effects";
import Auth from "./Auth";
import Users from "./Users";
import DoctorCategory from "./DoctorCategory";
import Doctors from "./Doctors";
import InactiveDoctors from "./InactiveDoctors";
import WithdrawRequest from "./WithdrawRequestSaga";
import Transaction from "./TransactionSaga";
import Dashboard from "./DashboardSaga";
import Settings from "./SettingsSaga";
import ContactUs from "./ContactUsSaga";
import Invoice from "./InvoiceSaga";
import GstInvoice from "./GstInvoiceSaga";
import AstrocallInvoice from "./AstrocallInvoiceSaga";
import GstAstrocallInvoice from "./GstAstrocallInvoiceSaga";
import Problem from "./ProblemSaga";
import CancelledInvoice from "./CancelledInvoiceSaga";
//plopImport

export default function* rootSaga(getState) {
  yield all([
    Auth(),
    Users(),
    DoctorCategory(),
    Doctors(),
    WithdrawRequest(),
    Transaction(),
    Dashboard(),
    Settings(),
    ContactUs(),
    Invoice(),
    GstInvoice(),
    AstrocallInvoice(),
    GstAstrocallInvoice(),
    Problem(),
    CancelledInvoice(),
    InactiveDoctors()
//plopSaga
  ]);
}
