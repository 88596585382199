export const GET_WITHDRAW_REQUEST_INIT = "GET_WITHDRAW_REQUEST_INIT";
export const GET_WITHDRAW_REQUEST_SUCCESS = "GET_WITHDRAW_REQUEST_SUCCESS";
export const GET_WITHDRAW_REQUEST_ERROR = "GET_WITHDRAW_REQUEST_ERROR";
//plopImport
export const SET_WIHTHDRAWAL_REQUEST_COMPLETE_INIT = "SET_WIHTHDRAWAL_REQUEST_COMPLETE_INIT";
export const SET_WIHTHDRAWAL_REQUEST_COMPLETE_SUCCESS = "SET_WIHTHDRAWAL_REQUEST_COMPLETE_SUCCESS";
export const SET_WIHTHDRAWAL_REQUEST_COMPLETE_ERROR = "SET_WIHTHDRAWAL_REQUEST_COMPLETE_ERROR";

export const SET_WIHTHDRAWAL_REQUEST_PENDING_INIT = "SET_WIHTHDRAWAL_REQUEST_PENDING_INIT";
export const SET_WIHTHDRAWAL_REQUEST_PENDING_SUCCESS = "SET_WIHTHDRAWAL_REQUEST_PENDING_SUCCESS";
export const SET_WIHTHDRAWAL_REQUEST_PENDING_ERROR = "SET_WIHTHDRAWAL_REQUEST_PENDING_ERROR";