import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  fetchInactiveDoctorFail,
  fetchInactiveDoctorSuccess,
  setInactiveDoctorAccountStatusSuccess,
  setInactiveDoctorAccountStatusError,
  //plopImportAction
} from "../actions/InactiveDoctors";
import {
  INACTIVE_DOCTOR_FETCH_INIT,
  SET_INACTIVE_DOCTOR_ACCOUNT_STATUS_INIT,
  //plopImportConstant
} from "redux/constants/InactiveDoctor";

import FirebaseService from "services/FirebaseService";

export function* getInactiveDoctors() {
  yield takeEvery(INACTIVE_DOCTOR_FETCH_INIT, function* () {
    try {
      const data = yield call(FirebaseService.fetchCollection, "Doctors");

      // Manual sorting by createdAt in descending order
      const sortedData = data.sort((a, b) => {
        const aSeconds = a.createdAt ? a.createdAt.seconds : 0;
        const bSeconds = b.createdAt ? b.createdAt.seconds : 0;
        return bSeconds - aSeconds;
      });

      // Manual filtering for accountStatus === "nonactive"
      const filteredData = sortedData.filter(element => element.accountStatus === "nonactive");

      let changeTime = filteredData.map((element) => {
        var d = new Date(0);
        if (element.createdAt && element.createdAt.seconds) {
          d.setUTCSeconds(element.createdAt.seconds);
        }
        return {
          ...element,
          createdAt: `${d}`,
        };
      });

      yield put(fetchInactiveDoctorSuccess(changeTime));
    } catch (error) {
      console.log("Error fetching inactive doctors:", error);
      yield put(fetchInactiveDoctorFail(error));
    }
  });
}


export function* setInactiveDoctorAccountStatus() {
  yield takeEvery(SET_INACTIVE_DOCTOR_ACCOUNT_STATUS_INIT, function* (action) {
    try {
      yield call(
        FirebaseService.setDoctorAccountStatus,
        action.doctorId,
        action.status
      );
      yield put(setInactiveDoctorAccountStatusSuccess(action.doctorId, action.status));
    } catch (error) {
      yield put(setInactiveDoctorAccountStatusError(error));
    }
  });
}
//plopSaga

export default function* rootSaga() {
  yield all([
    fork(getInactiveDoctors),
    fork(setInactiveDoctorAccountStatus),
    //plopExport
  ]);
}
