export const DOCTOR_FETCH_INIT = "DOCTOR_FETCH_INIT";
export const DOCTOR_FETCH_SUCCESS = "DOCTOR_FETCH_SUCCESS";
export const DOCTOR_FETCH_FAIL = "DOCTOR_FETCH_FAIL";
export const FETCH_TOP_RATED_DOCTOR_INIT = "FETCH_TOP_RATED_DOCTOR_INIT";
export const FETCH_TOP_RATED_DOCTOR_SUCCESS = "FETCH_TOP_RATED_DOCTOR_SUCCESS";
export const FETCH_TOP_RATED_DOCTOR_FAIL = "FETCH_TOP_RATED_DOCTOR_FAIL";
//plopImport
export const SET_DOCTOR_ACCOUNT_STATUS_INIT = "SET_DOCTOR_ACCOUNT_STATUS_INIT";
export const SET_DOCTOR_ACCOUNT_STATUS_SUCCESS = "SET_DOCTOR_ACCOUNT_STATUS_SUCCESS";
export const SET_DOCTOR_ACCOUNT_STATUS_ERROR = "SET_DOCTOR_ACCOUNT_STATUS_ERROR";
export const DELETE_DOCTOR_INIT = "DELETE_DOCTOR_INIT";
export const DELETE_DOCTOR_SUCCESS = "DELETE_DOCTOR_SUCCESS";
export const DELETE_DOCTOR_ERROR = "DELETE_DOCTOR_ERROR";
export const ADD_TOP_RATED_DOCTOR_INIT = "ADD_TOP_RATED_DOCTOR_INIT";
export const ADD_TOP_RATED_DOCTOR_SUCCESS = "ADD_TOP_RATED_DOCTOR_SUCCESS";
export const ADD_TOP_RATED_DOCTOR_ERROR = "ADD_TOP_RATED_DOCTOR_ERROR";
export const DELETE_TOP_RATED_INIT = "DELETE_TOP_RATED_INIT";
export const DELETE_TOP_RATED_SUCCESS = "DELETE_TOP_RATED_SUCCESS";
export const DELETE_TOP_RATED_ERROR = "DELETE_TOP_RATED_ERROR";
