import {
    GET_PROBLEM_INIT,
    GET_PROBLEM_SUCCESS,
    GET_PROBLEM_ERROR,
    SET_PROBLEM_REQUEST_ACCEPT_INIT,
    SET_PROBLEM_REQUEST_ACCEPT_SUCCESS,
    SET_PROBLEM_REQUEST_ACCEPT_ERROR,
    SET_PROBLEM_REQUEST_REJECT_INIT,
    SET_PROBLEM_REQUEST_REJECT_SUCCESS,
    SET_PROBLEM_REQUEST_REJECT_ERROR,
    SET_PROBLEM_REQUEST_REJECT_WITH_INVOICE_INIT,
    SET_PROBLEM_REQUEST_REJECT_WITH_INVOICE_SUCCESS,
    SET_PROBLEM_REQUEST_REJECT_WITH_INVOICE_ERROR,

    GET_PROBLEM_ASTROLOGER_INIT,
    GET_PROBLEM_ASTROLOGER_SUCCESS,
    GET_PROBLEM_ASTROLOGER_ERROR,
    SET_PROBLEM_ASTROLOGER_REQUEST_ACCEPT_INIT,
    SET_PROBLEM_ASTROLOGER_REQUEST_ACCEPT_SUCCESS,
    SET_PROBLEM_ASTROLOGER_REQUEST_ACCEPT_ERROR,
    SET_PROBLEM_ASTROLOGER_REQUEST_REJECT_INIT,
    SET_PROBLEM_ASTROLOGER_REQUEST_REJECT_SUCCESS,
    SET_PROBLEM_ASTROLOGER_REQUEST_REJECT_ERROR,
    SET_PROBLEM_ASTROLOGER_REQUEST_ACCEPT_WITH_INVOICE_INIT,
    SET_PROBLEM_ASTROLOGER_REQUEST_ACCEPT_WITH_INVOICE_SUCCESS,
    SET_PROBLEM_ASTROLOGER_REQUEST_ACCEPT_WITH_INVOICE_ERROR,
} from '../constants/ProblemConstants.js';

export const getProblemInit = () => ({
    type: GET_PROBLEM_INIT,
});

export const getProblemSuccess = (data) => ({
    type: GET_PROBLEM_SUCCESS,
    data
});

export const getProblemError = (error) => ({
    type: GET_PROBLEM_ERROR,
    error
});

export const setProblemRequestAcceptActionInit = (data) => ({
    type: SET_PROBLEM_REQUEST_ACCEPT_INIT,
    data,
  });
  
  export const setProblemRequestAcceptActionSuccess = (data) => ({
    type: SET_PROBLEM_REQUEST_ACCEPT_SUCCESS,
    data,
  });
  
  export const setProblemRequestAcceptActionError = (error) => ({
    type: SET_PROBLEM_REQUEST_ACCEPT_ERROR,
    error,
  });

  export const setProblemRequestRejectActionInit = (data) => ({
    type: SET_PROBLEM_REQUEST_REJECT_INIT,
    data,
  });
  
  export const setProblemRequestRejectActionSuccess = (data) => ({
    type: SET_PROBLEM_REQUEST_REJECT_SUCCESS,
    data,
  });
  
  export const setProblemRequestRejectActionError = (error) => ({
    type: SET_PROBLEM_REQUEST_REJECT_ERROR,
    error,
  });

  export const setProblemRequestRejectWithInvoiceActionInit = (data) => ({
    type: SET_PROBLEM_REQUEST_REJECT_WITH_INVOICE_INIT,
    data,
  });
  
  export const setProblemRequestRejectWithInvoiceActionSuccess = (data) => ({
    type: SET_PROBLEM_REQUEST_REJECT_WITH_INVOICE_SUCCESS,
    data,
  });
  
  export const setProblemRequestRejectWithInvoiceActionError = (error) => ({
    type: SET_PROBLEM_REQUEST_REJECT_WITH_INVOICE_ERROR,
    error,
  });


  export const getProblemAstrologerInit = () => ({
    type: GET_PROBLEM_ASTROLOGER_INIT,
});

export const getProblemAstrologerSuccess = (data) => ({
    type: GET_PROBLEM_ASTROLOGER_SUCCESS,
    data
});

export const getProblemAstrologerError = (error) => ({
    type: GET_PROBLEM_ASTROLOGER_ERROR,
    error
});

export const setProblemAstrologerRequestAcceptActionInit = (data) => ({
  type: SET_PROBLEM_ASTROLOGER_REQUEST_ACCEPT_INIT,
  data,
});

export const setProblemAstrologerRequestAcceptActionSuccess = (data) => ({
  type: SET_PROBLEM_ASTROLOGER_REQUEST_ACCEPT_SUCCESS,
  data,
});

export const setProblemAstrologerRequestAcceptActionError = (error) => ({
  type: SET_PROBLEM_ASTROLOGER_REQUEST_ACCEPT_ERROR,
  error,
});

export const setProblemAstrologerRequestRejectActionInit = (data) => ({
  type: SET_PROBLEM_ASTROLOGER_REQUEST_REJECT_INIT,
  data,
});

export const setProblemAstrologerRequestRejectActionSuccess = (data) => ({
  type: SET_PROBLEM_ASTROLOGER_REQUEST_REJECT_SUCCESS,
  data,
});

export const setProblemAstrologerRequestRejectActionError = (error) => ({
  type: SET_PROBLEM_ASTROLOGER_REQUEST_REJECT_ERROR,
  error,
});

export const setProblemAstrologerRequestAcceptWithInvoiceActionInit = (data) => ({
  type: SET_PROBLEM_ASTROLOGER_REQUEST_ACCEPT_WITH_INVOICE_INIT,
  data,
});

export const setProblemAstrologerRequestAcceptWithInvoiceActionSuccess = (data) => ({
  type: SET_PROBLEM_ASTROLOGER_REQUEST_ACCEPT_WITH_INVOICE_SUCCESS,
  data,
});

export const setProblemAstrologerRequestAcceptWithInvoiceActionError = (error) => ({
  type: SET_PROBLEM_ASTROLOGER_REQUEST_ACCEPT_WITH_INVOICE_ERROR,
  error,
});