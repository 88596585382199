import {
    GET_CONTACT_US_INIT,
    GET_CONTACT_US_SUCCESS,
    GET_CONTACT_US_ERROR
} from "../constants/ContactUsConstants.js";

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  delete: false,
};

const contactUs = (state = initialState, action) => {
    switch (action.type) {
        case GET_CONTACT_US_INIT:
            return { ...state, loading: true };
        case GET_CONTACT_US_SUCCESS:
            return { ...state, success: true, data: action.data, loading: false };
        case GET_CONTACT_US_ERROR:
            return { ...state, error: action.error,loading:false };
            //plopImport
        default:
            return state;
    
    }
}

export default contactUs;