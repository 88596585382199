import {
    GET_INVOICE_INIT,
    GET_INVOICE_SUCCESS,
    GET_INVOICE_ERROR,
    GET_GST_INVOICE_INIT,
    GET_GST_INVOICE_SUCCESS,
    GET_GST_INVOICE_ERROR,
  } from "../constants/InvoiceConstants.js";
  
  const initialState = {
    data: [],
    loading: false,
    error: null,
    success: false,
    delete: false,
  };
  
  const invoice = (state = initialState, action) => {
    switch (action.type) {
      case GET_INVOICE_INIT:
        return { ...state, loading: true };
      case GET_INVOICE_SUCCESS:
        return { ...state, success: true, data: action.data, loading: false };
      case GET_INVOICE_ERROR:
        return { ...state, error: action.error, loading: false };
      default:
        return state;
    }
  };
  
  export default invoice;
  